<template>
  <div>
    <div class="oval-right"></div>
    <!-- the login form popup -->
    <div class="register">
      <div class="login-title">
        <img
          src="../../assets/rac_logo@2x.png"
          alt
        />
      </div>
      <div class="tel">
        <div class="telPre">
          <!-- the pre of the phone number -->
          <div
            @click.stop.prevent="selectPre"
            class="tel-pre-box"
          >
            <span v-if="countryCodeList">{{
              countryCodeList[defaultPre].TelephoneCode
            }}</span>
            <i class="select-icon"></i>
          </div>

          <div class>
            <input
              type="tel"
              name="telphone"
              id="telphone"
              v-model.trim="telphone"
              placeholder="输入手机号"
              @blur.prevent="inputLoseFocus"
            />
          </div>
        </div>
      </div>
      <div class="tel-code">
        <div
          class="tel-code-box"
          v-if="disabled2 == true"
        >
          <div class="code">
            <input
              type="text"
              v-model="code"
              placeholder="请输入验证码"
              @blur.prevent="inputLoseFocus"
              maxlength="4"
            />
          </div>
          <!-- the btn get code -->
          <div
            v-show="!showTime"
            class="btn-disabled"
          >{{ countTime }}秒</div>
          <div v-show="showTime">
            <!-- <div   @click.prevent="getCode" class="codeBtn">获取验证码</div> -->
            <button
              :disabled="disabled"
              class="codeBtn"
              @click="getchangecode"
            >
              获取验证码
            </button>
          </div>
        </div>
        <vcode
          @confirmSuccess="vcodeChange"
          v-if="disabled2 == false"
        ></vcode>
      </div>
      <div class="invite">
        <div class="inviteIcon">
          <van-icon
            name="point-gift"
            size="20px"
            color="#ccc"
          />
        </div>
        <div
          @click.stop.prevent="selectPre2"
          class="tel-pre-box"
        >
          <span v-if="countryCodeList2">{{
            countryCodeList2[defaultPre2].TelephoneCode
          }}</span>
          <i class="select-icon"></i>
        </div>
        <div>
          <input
            type="text"
            placeholder="邀请人手机号(选填)"
            v-model.trim="telphone2"
          />
        </div>
      </div>
      <!-- login btn -->
      <!-- <div
        :class="['loginBtn', { 'login-disabled': disabledSubmit }]"
        @click="loginHandler"
      >
        登录
      </div> -->
      <div
        class="loginBtn"
        @click="loginHandler"
      >登录</div>
      <!-- <div
        class="protocol-tips text-center"
        @click="toDriver"
      >
        <span class="green">司机绑定微信</span>
      </div> -->

      <div class="protocol-tips text-center">
        点击登录即表示同意
        <span
          @click="$router.push(`/protocol/100201`)"
          class="green"
        >《用户协议》</span>
      </div>
      <div class="oval-left"></div>

      <!-- dropdown select list -->
      <!-- <transition name="slide">
        <div class="pre-select"
             v-show="showSelect">
          <ul>
            <li v-for="(c,index) in countryCodeList"
                :key="c.ID"
                :class="{'active':defaultPre==index}"
                @click="change(index)">{{c.TelephoneCode}}</li>
          </ul>
        </div>
      </transition>-->
      <!-- 号码前缀 -->

      <van-popup
        v-model="showTelPre"
        position="bottom"
        :overlay="true"
      >
        <van-picker
          show-toolbar
          title="选择国家区号"
          :columns="countryCodeList"
          @cancel="cacelTelPre"
          @confirm="confirmTelPre"
          value-key="TelephoneCode"
          ref="pickPeople"
        />
      </van-popup>
      <van-popup
        v-model="showTelPre2"
        position="bottom"
        :overlay="true"
      >
        <van-picker
          show-toolbar
          title="选择国家区号"
          :columns="countryCodeList2"
          @cancel="cacelTelPre2"
          @confirm="confirmTelPre2"
          value-key="TelephoneCode"
          ref="pickPeople"
        />
      </van-popup>
    </div>
    <!-- <oauth></oauth> -->
  </div>
</template>

<script>
// import oauth from "../oauth/oauth";
import { Toast, Picker, Popup, Icon } from "vant";
// import PhoneCode from '../phoneCode/phoneCode'
import Vcode from "@/components/vcode/vcode";
import { getVerificationCode, login } from "@/service/";
import { mapState, mapActions, mapMutations } from "vuex";
import { getStore, setStore, removeStore, clearStore } from "../../lib/storage";
import { validateTel, validateTelCode } from "@/lib/validate";
import { inputBlur } from "@/mixins/inputBlur";
import { IMappkey } from "@/config";
// import { setTimeout } from 'timers';fdsa

export default {
  name: "login",
  mixins: [inputBlur],
  components: {
    Vcode,
    [Toast.name]: Toast,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    // oauth: oauth
    // PhoneCode
  },

  // props: {
  //   showLogin: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data() {
    return {
      isWX: false,
      countryCodeList2: [
        {
          ID: 32,
          TimeDifference: -780,
          IsDefault: 1,
          ByCountriesName: "Canada",
          CountriesName: "加拿大",
          DomainAbbreviation: "CA",
          TelephoneCode: "+1",
        },
        {
          ID: 37,
          TimeDifference: 0,
          IsDefault: 1,
          ByCountriesName: "China",
          CountriesName: "中国",
          DomainAbbreviation: "CN",
          TelephoneCode: "+86",
        },
      ],
      form: {},
      //   show: true,
      IDcode: "",
      telphone: "",
      telphone2: "",
      code: "",
      showSelect: false,
      defaultPre: 0,
      defaultPre2: 0,
      // 验证码按钮初始化值
      codeText: "获取验证码",
      // 验证码key值
      codeKey: "1",
      // 验证码按钮 class
      codeBtn: "codeBtn",
      codeClick: false,
      phoneCodeText: this.codeText,
      showTelPre: false,
      showTelPre2: false,
      disabledSubmit: false,
      showTime: true, // 初始启用按钮
      countTime: "", // 初始化次数
      timer: null,
      confirmSuccess2: false,
      disabled: false,
      disabled2: true,
    };
  },

  computed: {
    ...mapState({
      // wxinfo: ({ userVal }) => userVal.wxinfo,
      countryCodeList: ({ globalVal }) => globalVal.countryCodeList,
    }),
    wxinfo() {
      return JSON.parse(getStore("wx")) || "";
    },
    showLogin: {
      get() {
        return this.$attrs.showLogin;
      },
      set() {},
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    ...mapMutations(["SET_USER_INFO"]),
    ...mapActions(["getUserInfo", "getCountry"]),
    // 扩展API准备完成后要执行的操作
    plusReady() {
      if (window.plus) {
        plus.device.getInfo({
          success: function (e) {
            console.log("pulus:", e);
            if (e.uuid) {
              setStore("uuid", e.uuid);
            }
          },
          fail: function (e) {
            console.log("getDeviceInfo failed: " + JSON.stringify(e));
            if (e.uuid) {
              setStore("uuid", e.uuid);
            }
          },
        });
      }
    },
    toDriver() {
      console.log("toDriver");
      this.$router.replace("/driver");
    },
    selectPre() {
      this.showTelPre = true;
      // this.showSelect = !this.showSelect
    },
    selectPre2() {
      this.showTelPre2 = true;
      // this.showSelect = !this.showSelect
    },
    // change (index) {
    //   this.defaultPre = index
    //   // this.showSelect = false
    // },
    getchangecode() {
      if (!this.isWX) {
        this.plusReady();
      }

      let pre = this.countryCodeList[this.defaultPre].TelephoneCode;

      localStorage.setItem("TelephoneCodepre", pre);
      let telMsg = validateTel(pre, this.telphone);
      if (telMsg !== true) {
        Toast(telMsg);
        return;
      }
      this.disabled2 = false;
    },
    vcodeChange(confirmSuccess2) {
      this.confirmSuccess2 = confirmSuccess2;
      if (this.confirmSuccess2 == true) {
        this.disabled2 = true;
        this.getCode();
      }
      // console.log(this.confirmSuccess2, "滑块");
    },
    // 确定选择人数
    confirmTelPre(value, index) {
      // this.peopleNum = value.text
      // console.log(value)
      // console.log(index)
      this.defaultPre = index;
      this.showTelPre = false;
      // this.SET_PEOPLE_NUM(value.num)
      // this.tripCarList = this.filterCarList()
      // this.SELECT_CAR(this.tripCarList[0])
      // console.log(this.tripCarList)
    },
    confirmTelPre2(value, index) {
      // this.peopleNum = value.text
      // console.log(value)
      // console.log(index)
      this.defaultPre2 = index;
      this.showTelPre2 = false;
      // this.SET_PEOPLE_NUM(value.num)
      // this.tripCarList = this.filterCarList()
      // this.SELECT_CAR(this.tripCarList[0])
      // console.log(this.tripCarList)
    },
    // 取消选择号码
    cacelTelPre() {
      this.showTelPre = false;
    },
    cacelTelPre2() {
      this.showTelPre2 = false;
    },
    async loginHandler() {
      console.log("loginHandler");
      let pre = this.countryCodeList[this.defaultPre].TelephoneCode;
      let pre2 = this.countryCodeList2[this.defaultPre2].TelephoneCode;

      localStorage.setItem("TelephoneCodepre", pre);
      let telMsg = validateTel(pre, this.telphone);
      console.log("telMsg:", telMsg);
      if (telMsg !== true) {
        Toast(telMsg);
        return;
      }

      let codeMsg = validateTelCode(this.code);
      let ShareID = pre2 + this.telphone2;
      console.log("codeMsg:", codeMsg);
      console.log("ShareID:", ShareID);
      if (codeMsg !== true) {
        Toast(codeMsg);
        return;
      }

      let params = [
        getStore("uuid") || "",
        this.telphone,
        ShareID,
        !this.isWX ? "" : this.wxinfo.openid ? this.wxinfo.openid : "",
        !this.isWX ? "" : this.wxinfo.headimgurl ? this.wxinfo.headimgurl : "",
        !this.isWX ? "" : this.wxinfo.nickname ? this.wxinfo.nickname : "",
        this.isWX ? "weixin" : "android",
        this.isWX ? "weixin" : "android",
        this.isWX ? "weixin" : "app",
        this.countryCodeList[this.defaultPre].TelephoneCode,
        this.code,
        "0",
        this.form.username,
        this.form.password,
        IMappkey,
      ];
      console.log("IMappkey__login:", IMappkey);
      console.log(params);
      // this.getUserInfo(params);
      //   return;
      // this.disabledSubmit = true;
      try {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true, // 禁用背景点击
          loadingType: "spinner",
          message: "loading...",
        });
        let res = await login(...params);

        // this.disabledSubmit = false;
        console.log("resresres:", res);
        console.log("code:", getStore("code"));
        if (res && res.code == 1) {
          //   clearStore();
          if (getStore("startAddress")) {
            removeStore("startAddress");
          }
          Toast.clear();
          console.log("login_res_424:", res);
          this.SET_USER_INFO(res.data);
          setStore("userInfo", JSON.stringify(res.data));
          setStore("token", res.token);
          // localStorage.setItem("userInfo", JSON.stringify(res.data));
          // localStorage.setItem("token", res.token);
          // this.$router.push({ path: '/home' })
          this.$router.replace("/home");
        } else {
          console.log("login_433");
          Toast(res.msg);
          this.disabledSubmit = false;
        }
      } catch (error) {
        console.log("error:", error);
        // this.disabledSubmit = false;
        Toast.clear();
      }
    },
    async getCode() {
      let pre = this.countryCodeList[this.defaultPre].TelephoneCode;
      this.disabled = true;
      this.showTime = false;
      if (!this.timer) {
        this.countTime = 60;
        this.timer = setInterval(() => {
          if (this.countTime > 0 && this.countTime <= 60) {
            this.countTime--;
          } else {
            this.disabled = false;
            this.showTime = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }

      try {
        let res = await getVerificationCode(pre + this.telphone, this.codeKey);
        if (res.code == 1) {
          console.log(res);
        } else {
          Toast(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
      // if (res.code === 1) {

      // } else {
      //   // this.$message.error({ message: data.msg })
      // }
    },
    init() {
      var ua = window.navigator.userAgent.toLowerCase();
      let _this = this;
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信浏览器
        this.isWX = true;
        setTimeout(() => {
          if (!getStore("wx")) {
            _this.$router.replace("/oauth");
          } else if (getStore("wx") && getStore("token")) {
            _this.$router.replace("/home");
          }
        }, 500);
      } else {
        this.isWX = false;
        if (getStore("token")) {
          this.$router.replace("/home");
        }
      }
      if (!this.isWX) {
        // 扩展API是否准备好，如果没有则监听“plusready"事件
        if (window.plus) {
          this.plusReady();
        } else {
          document.addEventListener("plusready", this.plusReady, false);
        }
      }
    },
  },
  mounted() {
    if (!this.countryCodeList) {
      this.getCountry();
      // this.vcodeChange()
    }
    //自动触发写入的函数
    this.init();
    // this.GetHostData();
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.oval {
  &-right {
    .size(300px, 268px);
    // text-align: right;
    margin-right: 0;
    margin-left: auto;
    background: url("../../assets/Oval_green.svg") right 0 no-repeat;
    background-size: 300px 268px;
  }

  &-left {
    position: absolute;
    left: 0;
    bottom: 0;
    .size(254px, 214px);
    background: url("../../assets/Oval_light_green.svg") 0 0 no-repeat;
    background-size: 254px 214px;
  }
}

.van {
  &-popup {
    position: absolute !important;
  }

  &-overlay {
    position: absolute !important;
  }
}

.register {
  // .size(690px, 512px);
  .login-title {
    padding-left: 70px;
    margin-bottom: 120px;
    width: 220px;
    height: 68px;

    img {
      width: 100%;
    }

    // font-size: 52px;
    // font-family: 'PingFangSC-Medium';
    // font-weight: 500;
    // color: rgba(51, 51, 51, 1);
    // line-height: 74px;
  }

  margin: 0 auto;
  padding: 0 50px;
  .bg(@white);
  border-radius: 20px !important;
  box-sizing: border-box;
  // position: relative;
  // transform: translateY(50%);
  //   box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}

.loginBtn {
  .size(520px, 100px);
  margin: 0 auto 60px;
  .text-center;
  line-height: 100px;
  background: rgba(12, 170, 50, 1);
  box-shadow: 0px 4px 70px 0px rgba(12, 170, 50, 0.5);
  border-radius: 50px;
  font-size: 30px;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.tel {
  width: 650px;
  height: 140px;
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 120px 0px rgba(12, 170, 50, 0.1);
  display: flex;
  flex: 1;
  // padding: 0 70px;
  align-items: center;
  // .size(72px, 92px);
  line-height: 42px;

  &-code {
    padding: 0 70px;
    margin-bottom: 160px;

    input {
      background: transparent;
    }

    &-box {
      display: flex;
      flex: 1;
      padding: 30px 0;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 2px solid rgba(238, 238, 238, 1);
    }
  }

  input {
    .fontSize(30px);
    line-height: 42px;
  }
}

.telPre {
  display: flex;
  flex: 1;
  padding: 0 70px;
  align-items: center;
  // .size(72px, 92px);
  line-height: 42px;

  .tel-pre-box {
    padding-left: 44px;
    background: url("../../assets/tel.svg") left center no-repeat;
    background-size: 32px 32px;
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    white-space: nowrap;
    // color: rgba(51, 51, 51, 1);
  }
}

.select-icon {
  display: inline-block;
  .size(12px, 20px);
  margin-left: 10px;
  margin-right: 20px;
  background: url("../../assets/back@2x.png") no-repeat left center;
  background-size: 12px 20px;
}

.code {
  padding-left: 42px;
  background: url("../../assets/tel_code.svg") left center no-repeat;
  background-size: 30px 36px;
  width: 240px;

  input {
    width: 100%;
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    // color: rgba(204, 204, 204, 1);
  }
}

.codeBtn {
  // .size(160px, 50px);
  .color(@brand-green);
  // background: rgba(75, 143, 91, 0.1);
  .text-center;
  line-height: 50px;
  .fontSize(24px);
  // .border-radius(4px);
  border: 0;
  background-color: #fff;
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  // background: rgba(0, 0, 0, 0.1);
  .color(#999);
}

.protocol-tips {
  margin: 80px 0 20px;
  .fontSize(24px);
  line-height: 34px;
  .color(#999);
}

.pre-select {
  width: 200px;
  height: 192px;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 30px;
  top: 132px;
  .fontSize(30px);
  .color(#999);
  .text-center;
  overflow-y: auto;

  // -webkit-overflow-scrolling: touch;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    height: 64px;
    border-bottom: 2px solid #eee;
    line-height: 64px;
  }
}

.slide-enter-active {
  animation: slide-in 0.2s;
}

.slide-leave-active {
  animation: slide-in 0.2s reverse;
}

@keyframes slide-in {
  0% {
    height: 0;
  }

  50% {
    height: 96px;
  }

  100% {
    height: 192px;
  }
}

.active {
  .bg(#eee);
}

.login-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.invite {
  display: flex;
  margin-top: -130px;
  margin-bottom: 70px;
  margin-left: 65px;
  font-size: 30px;
  padding: 30px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  .inviteIcon {
    padding: 0 5px 0 0;
    margin-left: -30px;
  }
}
</style>

